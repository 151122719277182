/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import PropTypes from "prop-types";
// import AirbnbPropTypes from "airbnb-prop-types";
import Context from "../mui-v4/context/Context";
import Validation from "../mui-v4/validation/Validation";
import ProgramLayout from "../mui-v4/program/ProgramLayout";
import Header from "../mui-v4/header/Header";
import Button from "../mui-v4/button/Button";
import Menu from "../mui-v4/menu/Menu";
import CloseIcon from "@mui/icons-material/Close";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Group from "../mui-v4/group/Group";
//import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProgramRow from "../mui-v4/program/ProgramRow";
import ProgramColumn from "../mui-v4/program/ProgramColumn";
import format from "date-fns/format";
import parse from "date-fns/parse";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ptBR from "date-fns/locale/pt-BR";
import Field from "../mui-v4/field/Field";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import Program from "../mui-v4/program/Program";
import { showError } from "./AppMessage";

import ParticipacaoAssociadoListar from "./participacaoAssociado/Listar";
import ArmaListar from "./arma/Listar";

import InscricaoEtapaAssociadoListar from "./inscricaoEtapaAssociado/Listar";
import InscricaoEtapaOutrosCampeonatoAssociadoListar from "./inscricaoEtapaOutrosCampeonatoAssociado/Listar";
import InscricaoSumulaAssociadoListar from "./inscricaoSumulaAssociado/Listar";
import AlterarSenhaEditar from "./alterarSenha/Editar";
import DadosAssociadoEditar from "./dadosAssociado/Editar";
import FichaFinanceiraAssociadoListar from "./fichaFinanceiraAssociado/Listar";
import AlterarClasseAssociadoListar from "./alterarClasseAssociado/Listar";
import AlterarEquipeAssociadoListar from "./alterarEquipeAssociado/Listar";

import ProdutoServicoConsultaAssociadoDetalhar from "./produtoServicoConsultaAssociado/Detalhar";

import ResultadoTrapIndividualListar from "./resultado/trapIndividual/Listar";
import ResultadoTrapEquipeListar from "./resultado/trapEquipe/Listar";

import ResultadoAcumuladoTrapIndividualListar from "./resultado/acumuladoTrapIndividual/Listar";
import ResultadoAcumuladoTrapEquipeListar from "./resultado/acumuladoTrapEquipe/Listar";
import ResultadoAcumuladoRankingTrapEquipeListar from "./resultado/acumuladoRankingTrapEquipe/Listar";
import ResultadoAcumuladoRankingTrapIndividualListar from "./resultado/acumuladoRankingTrapIndividual/Listar";

import { CampeonatoIcon, EtapaIcon, EtapaAbertaIcon, EtapaEncerradaIcon, EtapaFuturaIcon, InscricaoEtapaIcon, InscricaoSumulaIcon, ModalidadeIcon, ValidarIcon, SumulaIcon, UsuarioAtiradorIcon, FinanceiroIcon, ResultadoIcon, ClassificacaoIcon } from "./AppIcon";

/*
const styles = (theme) => {
  return {
    groupContainer: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "row wrap",
    },
    groupDescription: {
      width: "100%",
      padding: theme.spacing(1),
    },
    groupDescriptionText: {
      color: theme.palette.text.secondary,
      fontWeight: "normal",
    },
    group: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column nowrap",
      padding: theme.spacing(1),
      overflow: "hidden",
    },
    groupLayout: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
    },
    groupHeader: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minHeight: theme.spacing(4),
      paddingRight: theme.spacing(1),
    },
    groupHeaderIcon: {
      color: theme.palette.text.secondary,
    },
    groupBody: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      overflow: "hidden",
    },
    groupBodyHeader: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "row nowrap",
      minHeight: theme.spacing(4),
      alignItems: "center",
    },
    groupBodyHeaderText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.text.secondary,
      fontWeight: "bold",
      paddingLeft: theme.spacing(0),
    },
    groupBodyContent: {
      flex: "1 1 auto",
      display: "flex",
      flexFlow: "column nowrap",
    },
    groupText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.text.secondary,
      fontWeight: "normal",
    },
  };
};
*/

class AppMenuAssociado extends Program {
  static contextType = Context;

  static propTypes = {
    parameterMap: PropTypes.object.isRequired,
    // children: AirbnbPropTypes.empty(),
  };

  static defaultProps = {
    parameterMap: null,
    children: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      campeonatoRecordList: [],
      etapaRecordList: [],
      etapaFuturaVisivel: false,
      etapaEncerradaVisivel: true,

      record: {
        ca_campeonato: null,
      },
      ...props.state,
    };

    this.onOpen = this.onOpen.bind(this);
    this.updateEtapaRecordList = this.updateEtapaRecordList.bind(this);
    this.onCampeonatoChange = this.onCampeonatoChange.bind(this);

    /* */

    this.onAtualizar = this.onAtualizar.bind(this);
    this.onSair = this.onSair.bind(this);
    this.onInscricaoEtapaAssociadoListar = this.onInscricaoEtapaAssociadoListar.bind(this);
    this.onInscricaoEtapaOutrosCampeonatoAssociadoListar = this.onInscricaoEtapaOutrosCampeonatoAssociadoListar.bind(this);
    this.onInscricaoSumulaAssociadoListar = this.onInscricaoSumulaAssociadoListar.bind(this);
    this.onSwitchEtapaEncerradaVisivel = this.onSwitchEtapaEncerradaVisivel.bind(this);
    this.onSwitchEtapaFuturaVisivel = this.onSwitchEtapaFuturaVisivel.bind(this);
    this.onAlterarSenha = this.onAlterarSenha.bind(this);
    this.onAlterarDados = this.onAlterarDados.bind(this);
    this.onFichaFinanceira = this.onFichaFinanceira.bind(this);
    this.onMeusResultados = this.onMeusResultados.bind(this);
    this.onMeuAcervo = this.onMeuAcervo.bind(this);    
    this.onAlterarClasse = this.onAlterarClasse.bind(this);
    this.onAlterarEquipe = this.onAlterarEquipe.bind(this);

    this.onProdutoServicoConsultaAssociadoDetalhar = this.onProdutoServicoConsultaAssociadoDetalhar.bind(this);

    this.onResultadoTrapIndividualListar = this.onResultadoTrapIndividualListar.bind(this);
    this.onResultadoTrapEquipeListar = this.onResultadoTrapEquipeListar.bind(this);

    this.onResultadoAcumuladoTrapIndividualListar = this.onResultadoAcumuladoTrapIndividualListar.bind(this);
    this.onResultadoAcumuladoTrapEquipeListar = this.onResultadoAcumuladoTrapEquipeListar.bind(this);
    this.onResultadoAcumuladoRankingEquipeListar = this.onResultadoAcumuladoRankingEquipeListar.bind(this);
    this.onResultadoAcumuladoRankingIndividualListar = this.onResultadoAcumuladoRankingIndividualListar.bind(this);

    this.render = this.render.bind(this);
    this.renderPerfil = this.renderPerfil.bind(this);
    this.renderCampeonato = this.renderCampeonato.bind(this);
    this.renderEtapa = this.renderEtapa.bind(this);
    this.renderEtapaAtalho = this.renderEtapaAtalho.bind(this);
    this.renderEtapaModalidade = this.renderEtapaModalidade.bind(this);
    this.renderEtapaSumula = this.renderEtapaSumula.bind(this);
    this.renderEtapaProdutoServico = this.renderEtapaProdutoServico.bind(this);
    this.renderResultados = this.renderResultados.bind(this);
  }

  onOpen() {
    const props = this.props;
    const parameterMap = props.parameterMap;

    this.setState((state, props) => {
      return {
        ...state,
        campeonatoRecordList: parameterMap.campeonatoRecordList,
        etapaRecordList: this.updateEtapaRecordList(parameterMap.etapaRecordList),
      };
    });
  }

  updateEtapaRecordList(etapaRecordList) {
    etapaRecordList.forEach((etapaRecord, etapaRecordIndex) => {
      const today = parse(format(new Date(), "yyyy-MM-dd HH:mm"), "yyyy-MM-dd HH:mm", new Date());
      const et_inscricaoOnLineAbertura = parse(etapaRecord.et_inscricaoOnLineAbertura + " " + etapaRecord.et_inscricaoOnLineHoraAbertura, "yyyy-MM-dd HH:mm", new Date());
      const et_inscricaoOnLineEncerramento = parse(etapaRecord.et_inscricaoOnLineEncerramento + " 23:59", "yyyy-MM-dd HH:mm", new Date());


      const et_numero = etapaRecord.et_numero;      
      const isInTheFuture = isBefore(today, et_inscricaoOnLineAbertura);
      const isInThePast = isAfter(today, et_inscricaoOnLineEncerramento);
      const isOpen = !isInTheFuture && !isInThePast;

      etapaRecord.isInTheFuture = isInTheFuture;
      etapaRecord.isInThePast = isInThePast;
      etapaRecord.isOpen = isOpen;
    });

    return etapaRecordList;
  }

  onCampeonatoChange() {
    this.setState((state, props) => {
      return {
        ...state,
      };
    });
  }

  async onAtualizar() {
    try {
      const response = await this.fetch("/session/home/atualizar", {});

      this.setState((state, props) => {
        return {
          ...state,
          campeonatoRecordList: response.campeonatoRecordList,
          etapaRecordList: this.updateEtapaRecordList(response.etapaRecordList),
        };
      });
    } catch (error) {
      showError(this, error, null);
    }
  }

  onSair() {
    const location = window.location;

    location.reload();
  }

  onInscricaoEtapaAssociadoListar(etapaRecord) {
    this.openDialog(
      <InscricaoEtapaAssociadoListar
        parameterMap={{
          iet_campeonato: etapaRecord.et_campeonato,
          iet_etapa: etapaRecord.et_etapa,
        }}
        onClose={(inscricaoEtapaEditar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onInscricaoEtapaOutrosCampeonatoAssociadoListar(etapaRecord) {
    this.openDialog(
      <InscricaoEtapaOutrosCampeonatoAssociadoListar
        parameterMap={{
          ieo_campeonato: etapaRecord.et_campeonato,
          ieo_etapa: etapaRecord.et_etapa,
        }}
        onClose={(inscricaoEtapaEditar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onInscricaoSumulaAssociadoListar(etapaRecord) {
    this.openDialog(
      <InscricaoSumulaAssociadoListar
        parameterMap={{
          isu_campeonato: etapaRecord.et_campeonato,
          isu_etapa: etapaRecord.et_etapa,
        }}
        onClose={(inscricaoSumulaListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onSwitchEtapaFuturaVisivel() {
    this.setState((state, props) => {
      return {
        ...state,
        etapaFuturaVisivel: !state.etapaFuturaVisivel,
      };
    });
  }

  onSwitchEtapaEncerradaVisivel() {
    this.setState((state, props) => {
      return {
        ...state,
        etapaEncerradaVisivel: !state.etapaEncerradaVisivel,
      };
    });
  }

  onAlterarSenha() {
    this.openDialog(
      <AlterarSenhaEditar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onAlterarDados() {
    this.openDialog(
      <DadosAssociadoEditar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onFichaFinanceira() {
    this.openDialog(
      <FichaFinanceiraAssociadoListar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onMeusResultados() {
    this.openDialog(
      <ParticipacaoAssociadoListar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onMeuAcervo() {
    this.openDialog(
      <ArmaListar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }
 

  onAlterarClasse() {
    this.openDialog(
      <AlterarClasseAssociadoListar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onAlterarEquipe() {
    this.openDialog(
      <AlterarEquipeAssociadoListar
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onProdutoServicoConsultaAssociadoDetalhar(etapaRecord) {
    this.openDialog(
      <ProdutoServicoConsultaAssociadoDetalhar
        parameterMap={{
          ...etapaRecord,
          as_associado: this.getVP("vp_associado"),
          su_entidade: this.getVP("vp_entidade"),
        }}
        onClose={(program) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoTrapIndividualListar(etapaRecord) {
    this.openDialog(
      <ResultadoTrapIndividualListar
        parameterMap={{
          et_etapa: Validation.isDefined(etapaRecord) ? etapaRecord.et_etapa : null,
          et_nome: Validation.isDefined(etapaRecord) ? etapaRecord.et_nome : null,
        }}
        onClose={(resultadoTrapIndividualListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoTrapEquipeListar(etapaRecord) {
    this.openDialog(
      <ResultadoTrapEquipeListar
        parameterMap={{
          et_etapa: Validation.isDefined(etapaRecord) ? etapaRecord.et_etapa : null,
          et_nome: Validation.isDefined(etapaRecord) ? etapaRecord.et_nome : null,
        }}
        onClose={(resultadoTrapEquipeListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoAcumuladoTrapIndividualListar(campeonatoRecord) {
    this.openDialog(
      <ResultadoAcumuladoTrapIndividualListar
        parameterMap={{
          ca_campeonato: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_campeonato : null,
          ca_nome: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_nome : null,
        }}
        onClose={(resultadoAcumuladoTrapIndividualListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoAcumuladoTrapEquipeListar(campeonatoRecord) {
    this.openDialog(
      <ResultadoAcumuladoTrapEquipeListar
        parameterMap={{
          ca_campeonato: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_campeonato : null,
          ca_nome: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_nome : null,
        }}
        onClose={(resultadoAcumuladoTrapEquipeListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoAcumuladoRankingEquipeListar(campeonatoRecord) {
    this.openDialog(
      <ResultadoAcumuladoRankingTrapEquipeListar
        parameterMap={{
          ca_campeonato: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_campeonato : null,
          ca_nome: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_nome : null,
        }}
        onClose={(resultadoAcumuladoRankingTrapEquipeListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  onResultadoAcumuladoRankingIndividualListar(campeonatoRecord) {
    this.openDialog(
      <ResultadoAcumuladoRankingTrapIndividualListar
        parameterMap={{
          ca_campeonato: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_campeonato : null,
          ca_nome: Validation.isDefined(campeonatoRecord) ? campeonatoRecord.ca_nome : null,
        }}
        onClose={(resultadoAcumuladoRankingTrapEquipeListar) => {
          this.onAtualizar();
        }}
      />
    );
  }

  /* prettier-ignore */
  render() {
    const context = this.context;
    const app = context.app;
    const appState = app.state;

    const state = this.state;
    const campeonatoRecordList = state.campeonatoRecordList;
    const etapaRecordList = state.etapaRecordList;
    const etapaFuturaVisivel = state.etapaFuturaVisivel;
    const etapaEncerradaVisivel = state.etapaEncerradaVisivel;

    if ((campeonatoRecordList.length === 0) || (etapaRecordList.length === 0))  {
      return null;
    } else {
      const avatar = (<UsuarioAtiradorIcon/>);
      const caption = appState.vp_associadoNome;
      const description = "Visão geral dos seus Campeonatos e Etapas";
      const toolbar = (
        <Menu.IconButton icon={<MoreVertIcon/>} name='programaMenuMaisOpcoes' text='Mais opções'>
          <Menu.Item icon={<VpnKeyIcon/>} name='programaMenuAlterarSenha' text="Alterar Senha" onClick={this.onAlterarSenha}/>
          <Menu.Item icon={<PersonIcon/>} name='programaMenuAlterarDados' text="Alterar Dados Cadastrais" onClick={this.onAlterarDados}/>
          <Menu.Item icon={<AttachMoneyIcon/>} name='programaMenuFichaFinanceira' text="Ficha Financeira" onClick={this.onFichaFinanceira}/>
          <Divider/>
          <Menu.Item icon={<EtapaFuturaIcon/>} name='programaMenuEtapaFutura' text={etapaFuturaVisivel ? "Ocultar Etapas futuras" : "Exibir Etapas futuras"} onClick={this.onSwitchEtapaFuturaVisivel}/>
          <Menu.Item icon={<EtapaEncerradaIcon/>} name='programaMenuEtapaEncerrada' text={etapaEncerradaVisivel ? "Ocultar Etapas encerradas" : "Exibir Etapas encerradas"} onClick={this.onSwitchEtapaEncerradaVisivel}/>
          <Menu.Item icon={<CloseIcon/>} name='programaMenuSair' text="Sair" onClick={this.onSair}/>
        </Menu.IconButton>
      );

      return (
        <ProgramLayout program={this} width="1280px" height="minimum">
          <Header
            avatar={avatar}
            caption={caption}
            description={description}
            toolbar={toolbar}
          />
          <ProgramRow>
            <ProgramColumn width="400px" expanded={false}>
              {this.renderPerfil()}
              {this.renderCampeonato()}
            </ProgramColumn>
            <ProgramColumn width="400px" expanded={true}>
              {this.renderEtapa()}
            </ProgramColumn>
          </ProgramRow>
        </ProgramLayout>
      );
    }
  }

  /* prettier-ignore */
  renderPerfil() {
    // const props = this.props;
//    const theme = props.theme;
//    const classes = props.classes;

    return (
      <Group
        key={-1}
        avatar={<PersonIcon/>}
        name="perfilGroup"
        caption={this.context.app.state.vp_associadoNome}
        description="Menu de utilidades do usuário"
        expanded={false}
        collapsable={true}
        collapsed={false}
        toolbar={null}
        menu={null}
      >
        <Box /*className={classes.group}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", padding: theme.spacing(1),overflow: "hidden",})}>
          <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
            Abaixo você pode utilizar as opções para alterar sua Senha, seus Dados Pessoais e verificar sua Ficha Financeira.
            <br/>
          </Typography>
          <a href="#" onClick={this.onAlterarSenha}>
            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
              Alterar Senha
            </Typography>
          </a>
          <a href="#" onClick={this.onAlterarDados}>
            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
              Alterar Dados Pessoais
            </Typography>
          </a>
          <a href="#" onClick={this.onFichaFinanceira}>
            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
              Ficha Financeira
            </Typography>
          </a>
          <a href="#" onClick={this.onMeusResultados}>
            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
              Minhas Participações
            </Typography>
          </a>
            <a href="#" onClick={this.onMeuAcervo}>
            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
              Meu Acervo
            </Typography>
          </a>
        </Box>
      </Group>
    );
  }

  /* prettier-ignore */
  renderCampeonato() {
//    const props = this.props;
//    const theme = props.theme;
//    const classes = props.classes;

    const state = this.state;
    const record = state.record;
    const campeonatoRecordList = state.campeonatoRecordList;
    const campeonatoFilterList = campeonatoRecordList.filter((campeonatoRecord, campeonatoRecordIndex) => {
      return campeonatoRecord.ca_campeonato === this.state.record.ca_campeonato;
    });
    const campeonatoRecord = campeonatoFilterList.length === 1 ? campeonatoFilterList[0] : null;

    return (
      <Group
        key={1}
        avatar={<CampeonatoIcon/>}
        name="campeonatoGroup"
        caption="Campeonato"
        description="Visão geral do Campeonato"
        expanded={false}
        collapsable={true}
        collapsed={false}
        toolbar={null}
        menu={null}
      >
        <Box /*className={classes.group}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", padding: theme.spacing(1),overflow: "hidden",})}>
          <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
            Selecione abaixo o Campeonato desejado:
            <br/>
          </Typography>

          <div style={{margin: "8px", marginRight: "-8px", marginLeft: "-8px"}}>
            <Field.Combo
              name="ca_campeonato"
              caption="ca_nome"
              record={record}
              recordArray={campeonatoRecordList}
              label='Campeonato'
              onChange={this.onCampeonatoChange}
            />
          </div>

          {(campeonatoRecord !== null) && (campeonatoRecord.ca_campeonato !== 9) && (
            <React.Fragment>
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                As opções para Alteração de Classe e Alteração de Equipe estão disponíveis abaixo:
                <br/>
              </Typography>
              <a href="#" onClick={this.onAlterarClasse}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Alterar Classe
                </Typography>
              </a>
              <a href="#" onClick={this.onAlterarEquipe}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Alterar Equipe
                </Typography>
              </a>

              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2" style={{marginTop: "16px"}}>
                Os relatórios com os resultados acumulados estão disponíveis abaixo:
                <br/>
              </Typography>
              <a href="#" onClick={() => {this.onResultadoAcumuladoTrapIndividualListar(campeonatoRecord);}}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Acumulado Trap Individual
                </Typography>
              </a>
              <a href="#" onClick={() => {this.onResultadoAcumuladoTrapEquipeListar(campeonatoRecord);}}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Acumulado Trap por Equipe
                </Typography>
              </a>
              <a href="#" onClick={() => {this.onResultadoAcumuladoRankingEquipeListar(campeonatoRecord);}}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Ranking por Equipe
                </Typography>
              </a>
            </React.Fragment>
          )}

          {(campeonatoRecord !== null) && (campeonatoRecord.ca_campeonato === 9 || campeonatoRecord.ca_campeonato === 17) && (
            <React.Fragment>
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                As opções para Alteração de Classe e Alteração de Equipe estão disponíveis abaixo:
                <br/>
              </Typography>
              <a href="#" onClick={this.onAlterarClasse}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Alterar Classe
                </Typography>
              </a>
              <a href="#" onClick={this.onAlterarEquipe}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Alterar Equipe
                </Typography>
              </a>

              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2" style={{marginTop: "16px"}}>
                Os relatórios com os resultados acumulados estão disponíveis abaixo:
                <br/>
              </Typography>
              <a href="#" onClick={() => {this.onResultadoAcumuladoTrapIndividualListar(campeonatoRecord);}}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Acumulado Trap Individual
                </Typography>
              </a>
              <a href="#" onClick={() => {this.onResultadoAcumuladoRankingIndividualListar(campeonatoRecord);}}>
                <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                  Ranking Individual
                </Typography>
              </a>
            </React.Fragment>
          )}
        </Box>
      </Group>
    );
  }

  /* prettier-ignore */
  renderEtapa() {
//    const props = this.props;
//    const theme = props.theme;
//    const classes = props.classes;

    const state = this.state;
    const record = state.record;
    const ca_campeonato = record.ca_campeonato;
    const etapaRecordList = state.etapaRecordList;
    const etapaFilterList = etapaRecordList.filter((etapaRecord, etapaRecordIndex) => {
      return etapaRecord.et_campeonato === ca_campeonato;
    });
    const etapaFuturaVisivel = state.etapaFuturaVisivel;
    const etapaEncerradaVisivel = state.etapaEncerradaVisivel;

    if (etapaFilterList.length === 0) {
      return (
        <Group
          key={1}
          avatar={<EtapaFuturaIcon/>}
          name="campeonatoGroup"
          caption="Etapas"
          description="Visão Geral das Etapas do Campeonato"
          expanded={false}
          collapsable={false}
          collapsed={false}
          toolbar={null}
          menu={null}
        >
          <Box /*className={classes.group}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", padding: theme.spacing(1),overflow: "hidden",})}>
            <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
              Para exibir as Etapas do Campeonato, primeiro selecione o Campeonato desejado.
              <br/>
            </Typography>
          </Box>
        </Group>
      );
    } else {
      let todasEtapasFuturas = true;

      etapaFilterList.forEach((etapaRecord, etapaRecordIndex) => {
        todasEtapasFuturas = (todasEtapasFuturas && etapaRecord.isInTheFuture);
      });


      return etapaFilterList.map((etapaRecord) => {
        if ((todasEtapasFuturas) ||
            (etapaRecord.isOpen) ||
            (etapaRecord.isInTheFuture && etapaFuturaVisivel) ||
            (etapaRecord.isInThePast && etapaEncerradaVisivel)
          ) {

          let toolbar = null;
          let menu = null;

          if (etapaRecord.isOpen) {
            toolbar = (
              <React.Fragment>
                <Menu.IconButton icon={<ModalidadeIcon/>} name='etapaMenuInscricaoEtapa' text="Selecionar Modalidades" onClick={() => {this.onInscricaoEtapaAssociadoListar(etapaRecord);}}/>
                <Menu.IconButton icon={<ValidarIcon/>} name='etapaMenuInscricaoEtapaOutrosCampeonato' text="Validar Outros Campeonatos" onClick={() => {this.onInscricaoEtapaOutrosCampeonatoAssociadoListar(etapaRecord);}}/>
                <Menu.IconButton icon={<SumulaIcon/>} name='etapaMenuInscricaoSumula' text="Selecionar Súmulas" onClick={() => {this.onInscricaoSumulaAssociadoListar(etapaRecord);}}/>
              </React.Fragment>
            );
            menu = [
              <Menu.Item icon={<ModalidadeIcon/>} name='etapaMenuInscricaoEtapa' text="Selecionar Modalidades" onClick={() => {this.onInscricaoEtapaAssociadoListar(etapaRecord);}}/>,
              <Menu.Item icon={<ValidarIcon/>} name='etapaMenuInscricaoEtapaOutrosCampeonato' text="Validar Outros Campeonatos" onClick={() => {this.onInscricaoEtapaOutrosCampeonatoAssociadoListar(etapaRecord);}}/>,
              <Menu.Item icon={<SumulaIcon/>} name='etapaMenuInscricaoSumula' text="Selecionar Súmulas" onClick={() => {this.onInscricaoSumulaAssociadoListar(etapaRecord);}}/>,
            ];
          } else {
            if (etapaRecord.isInTheFuture) {
              toolbar = (
                <React.Fragment>
                  <Menu.IconButton icon={<ModalidadeIcon/>} name='etapaMenuInscricaoEtapa' text="Selecionar Modalidades" onClick={() => {this.onInscricaoEtapaAssociadoListar(etapaRecord);}}/>
                </React.Fragment>
              );
              menu = [
                <Menu.Item icon={<ModalidadeIcon/>} name='etapaMenuInscricaoEtapa' text="Selecionar Modalidades" onClick={() => {this.onInscricaoEtapaAssociadoListar(etapaRecord);}}/>,
              ];
            }
          }

          return (
            <Group
              key={etapaRecord.et_etapa}
              avatar={(etapaRecord.isInTheFuture ? <EtapaFuturaIcon/>    :
                       etapaRecord.isOpen        ? <EtapaAbertaIcon/>    :
                       etapaRecord.isInThePast   ? <EtapaEncerradaIcon/> :
                       ""
              )}
              name={"etapaGroup" + etapaRecord.et_etapa}
              caption={etapaRecord.et_nome}
              description={(etapaRecord.isInTheFuture ? "Etapa a ser realizada" :
                            etapaRecord.isOpen        ? "Etapa sendo realizada" :
                            etapaRecord.isInThePast   ? "Etapa realizada"      :
                            ""
                           ) +
                           " entre " +
                           format(parse(etapaRecord.et_inscricaoOnLineAbertura, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') +
                           " e " +
                           format(parse(etapaRecord.et_inscricaoOnLineEncerramento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
              }
              expanded={false}
              collapsable={true}
              collapsed={!etapaRecord.isOpen}
              toolbar={toolbar}
              menu={menu}
            >
              <Box /*className={classes.groupContainer}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "row wrap",})}>
                <Box /*className={classes.groupDescription}*/ sx={(theme) => ({width: "100%", padding: theme.spacing(1), paddingLeft: theme.spacing(8)})}>
                    {etapaRecord.et_observacao.split("\n").map((line, lineIndex) => {
                      return (
                        <Typography key={lineIndex} /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                          {(Validation.isString(line) && line.length === 0 ? <br/> : line)}
                        </Typography>
                      );
                    })}
                    <br/>
                </Box>
                {(this.renderEtapaModalidade(etapaRecord))}
                {(this.renderEtapaSumula(etapaRecord))}
                {(this.renderResultados(etapaRecord))}
                {(this.renderEtapaProdutoServico(etapaRecord))}
              </Box>
            </Group>
          );
        } else {
          return null;
        }
      });
    }
  }

  /* prettier-ignore */
  renderEtapaAtalho(etapaRecord) {
/*
    const {
      classes
    } = this.props;
*/
    const atalhos = [
      {name: "etapaAtalhoInscricaoEtapa",
       label: "Inscrições\nda Etapa",
       icon: (<InscricaoEtapaIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
       onClick: (() => {this.onInscricaoEtapaListar(etapaRecord);}),
       disabled: false,
      },
      {name: "etapaAtalhoInscricaoSumula",
       label: "Inscrições\ndas Súmulas",
       icon: (<InscricaoSumulaIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
       onClick: (() => {this.onInscricaoSumulaListar(etapaRecord);}),
       disabled: false,
      },
      {name: "etapaAtalhoInscricaoSumulaResultado",
       label: "Resultados\ndas Súmulas",
       icon: (<ResultadoIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
       onClick: (() => {this.onInscricaoSumulaResultadoListar(etapaRecord);}),
       disabled: false,
      },
      {name: "etapaAtalhoNull2",
       label: "Consumo\ndo Atirador",
       icon: (<FinanceiroIcon style={{alignSelf: "center", width: "48px", height: "48px"}}/>),
       onClick: (() => {this.onProdutoServicoAtiradorListar(etapaRecord);}),
       disabled: false,
      },
    ];

    return atalhos.map((atalho, atalhoIndex) => {
      return (
        <Box /*className={classes.group}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", padding: theme.spacing(1),overflow: "hidden",})} key={"atalho_" + atalhoIndex}>
          <Box /*className={classes.groupLayout}*/ sx={(theme) => ({display: "flex", flexFlow: "row nowrap", alignItems: "flex-start",})} style={{flexFlow: "row wrap"}}>
            <Box /*className={classes.group}*/ sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", padding: theme.spacing(1),overflow: "hidden",})} style={{width: "150px"}}>
              <Button disableFocusRipple name={atalho.name} onClick={atalho.onClick} variant="text" size="large" color="primary" fullWidth={true} style={{justifyContent: "flex-start", textTransform: 'none', whiteSpace: "normal"}} disabled={atalho.disabled}>
                <div style={{flex: "1 0 auto", display: "flex", flexFlow: "column wrap", justifyContent: "center"}}>
                  {atalho.icon}
                  {atalho.label.split("\n").map((line, lineIndex) => {
                    return (
                      <div key={lineIndex}>
                        {(Validation.isString(line) && line.length === 0 ? <br/> : line)}
                      </div>
                    );
                  })}
                </div>
              </Button>
            </Box>
          </Box>
        </Box>
      );
    });
  }

  /* prettier-ignore */
  renderEtapaModalidade(etapaRecord) {
    /*
    const {
      // theme,
      classes
    } = this.props;
    */

    const modalidadeRecordList = Validation.isDefined(etapaRecord.modalidadeRecordList) ? etapaRecord.modalidadeRecordList : [];

    return (
      <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", width: "300px", padding: theme.spacing(1)})}>
        <div style={{display: "flex", flexFlow: "row nowrap"}}>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
            <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
              <Avatar sx={(theme) => ({backgroundColor: theme.palette.primary.light})}>
                <ModalidadeIcon/>
              </Avatar>
            </div>
            {(modalidadeRecordList.length === -1) && (
              <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                <Divider orientation="vertical" style={{width: "2px"}}/>
              </div>
            )}
          </Box>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
            <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
              <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                Modalidades
              </Typography>
            </Box>
            {((etapaRecord.isOpen === true) || (true)) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                Utilize o ícone ou o menu lateral para selecionar as Modalidades em que você competirá nesta etapa.
              </Typography>
            )}
            {(modalidadeRecordList.length === 0) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal', paddingTop: theme.spacing(2)})} variant="body2">
                Nenhuma Modalidade selecionada.
              </Typography>
            )}
            <br/>

            {(modalidadeRecordList.length !== 0) && (
              modalidadeRecordList.map((modalidadeRecord, modalidadeRecordIndex) => {
                const sameCampeonato = (modalidadeRecordIndex > 0 ? modalidadeRecord.ca_campeonato === modalidadeRecordList[modalidadeRecordIndex - 1].ca_campeonato : false);

                return (
                  <React.Fragment key={modalidadeRecord.iet_modalidade}>
                    {(!sameCampeonato) && (
                      <div style={{display: "flex", flexFlow: "row nowrap"}}>
                        <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
                          <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
                            <Avatar>
                              <CampeonatoIcon/>
                            </Avatar>
                          </div>
                          <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                            <Divider orientation="vertical" style={{width: "2px"}}/>
                          </div>
                        </Box>
                        <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
                          <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
                            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                              {modalidadeRecord.ca_nome}
                            </Typography>
                          </Box>
                          <br/>
                        </Box>
                      </div>
                    )}

                    <div style={{display: "flex", flexFlow: "row nowrap"}}>
                      <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
                        <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
                          <Avatar sx={(theme) => ({width: theme.spacing(2), height: theme.spacing(2)})}>
                            &nbsp;
                          </Avatar>
                        </div>
                        {(modalidadeRecordIndex !== modalidadeRecordList.length - 1) &&
                         (modalidadeRecord.ca_campeonato === modalidadeRecordList[modalidadeRecordIndex + 1].ca_campeonato) && (
                          <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                            <Divider orientation="vertical" style={{width: "2px"}}/>
                          </div>
                        )}
                      </Box>
                      <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), position: "relative", top: "-2px"})}>
                        <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal',})} style={{fontWeight: "bold"}} variant="body2">{modalidadeRecord.iet_modalidade_nome}</Typography>
                        <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">{modalidadeRecord.mo_observacao}</Typography>
                        <br/>
                      </Box>
                    </div>

                  </React.Fragment>
                );
              })
            )}
          </Box>
        </div>
      </Box>
    );
  }

  /* prettier-ignore */
  renderEtapaSumula(etapaRecord) {
    /*
    const {
      //theme,
      classes
    } = this.props;
    */

    const sumulaRecordList = Validation.isDefined(etapaRecord.sumulaRecordList) ? etapaRecord.sumulaRecordList : [];

    return (
      <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", width: "300px", padding: theme.spacing(1)})}>
        <div style={{display: "flex", flexFlow: "row nowrap"}}>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
            <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
              <Avatar sx={(theme) => ({backgroundColor: theme.palette.primary.light})}>
                <SumulaIcon/>
              </Avatar>
            </div>
            {(sumulaRecordList.length === -1) && (
              <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                <Divider orientation="vertical" style={{width: "2px"}}/>
              </div>
            )}
          </Box>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
            <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
              <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                Súmulas
              </Typography>
            </Box>
            {(etapaRecord.isOpen === true) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                Após selecionar as Modalidades em que você competirá nesta etapa, utilize o ícone ou o menu lateral para selecionar as Súmulas em que você participará nesta etapa.
              </Typography>
            )}
            {(sumulaRecordList.length === 0) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal', paddingTop: theme.spacing(2)})} variant="body2">
                Nenhuma Súmula selecionada.
              </Typography>
            )}
            <br/>

            {(sumulaRecordList.length !== 0) && (
              sumulaRecordList.map((sumulaRecord, sumulaRecordIndex) => {
                const sameDate = (sumulaRecordIndex > 0 ? sumulaRecord.isu_data === sumulaRecordList[sumulaRecordIndex - 1].isu_data : false);
                const dateString = format(parse(sumulaRecord.isu_data, 'yyyy-MM-dd', new Date()), 'EEEE, dd/MM/yyyy', {locale: ptBR});
                const date = dateString.substring(0, 1).toUpperCase() + dateString.substring(1);

                const isu_resultado = sumulaRecord.isu_resultado;
                let   resultado = "Pendente"

                if ((Validation.isDefined(isu_resultado)) && (isu_resultado.length > 0)) {
                  resultado = isu_resultado.replaceAll("E", "").length + "/" + isu_resultado.length;
                }

                return (
                  <React.Fragment key={sumulaRecord.isu_sumula}>
                    {(!sameDate) && (
                      <div style={{display: "flex", flexFlow: "row nowrap"}}>
                        <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
                          <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
                            <Avatar>
                              <EtapaIcon/>
                            </Avatar>
                          </div>
                          <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                            <Divider orientation="vertical" style={{width: "2px"}}/>
                          </div>
                        </Box>
                        <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
                          <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
                            <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                              {date}
                            </Typography>
                          </Box>
                          <br/>
                        </Box>
                      </div>
                    )}
                    <div style={{display: "flex", flexFlow: "row nowrap"}}>
                      <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5), minWidth: theme.spacing(5)})}>
                        <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
                          <Avatar sx={(theme) => ({width: theme.spacing(2), height: theme.spacing(2)})}>
                            &nbsp;
                          </Avatar>
                        </div>
                        {(sumulaRecordIndex !== sumulaRecordList.length - 1) && (
                          <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                            <Divider orientation="vertical" style={{width: "2px"}}/>
                          </div>
                        )}
                      </Box>
                      <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), position: "relative", top: "-2px", overflow: "hidden"})}>
                          <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal',})} style={{fontWeight: "bold"}} variant="body2">
                            {"Súmula " + sumulaRecord.su_numero + ", posição " + sumulaRecord.isu_posicao}
                          </Typography>
                        <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                          {sumulaRecord.modalidadeRecordList.map((modalidadeRecord, modalidadeRecordIndex) => {
                            return modalidadeRecord.mo_nome + (modalidadeRecordIndex !== sumulaRecord.modalidadeRecordList.length - 1 ? ", " : "");
                          })}
                        </Typography>
                        <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal',})} style={{marginBottom: "8px"}} variant="body2">
                          {"Resultado: " + resultado}
                        </Typography>
                        <br/>
                      </Box>
                    </div>
                  </React.Fragment>
                );
              })
            )}
          </Box>
        </div>
      </Box>
    );
  }

  /* prettier-ignore */
  renderEtapaProdutoServico(etapaRecord) {
    /*
    const {
      //theme,
      classes
    } = this.props;
    */

    const produtoServicoRecordList = Validation.isDefined(etapaRecord.produtoServicoRecordList) ? etapaRecord.produtoServicoRecordList : [];
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    let saldo = 0;
    let totalPIX = 0;

    produtoServicoRecordList.forEach((produtoServico, produtoServicoIndex) => {
      if (produtoServico.ps_produtoServico !== produtoServico.en_produtoServicoPIX) {
          saldo = saldo + produtoServico.vTotalProdutoServico;
      } else {
          totalPIX = totalPIX + (-1 * produtoServico.vTotalProdutoServico);
      }
    });

    return (
      <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", width: "300px", padding: theme.spacing(1)})}>
        <div style={{display: "flex", flexFlow: "row nowrap"}}>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
            <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
              <Avatar sx={(theme) => ({backgroundColor: theme.palette.primary.light})}>
                <FinanceiroIcon/>
              </Avatar>
            </div>
            {(produtoServicoRecordList.length === -1) && (
              <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                <Divider orientation="vertical" style={{width: "2px"}}/>
              </div>
            )}
          </Box>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
            <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
              <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                Financeiro
              </Typography>
            </Box>

            {(produtoServicoRecordList.length === 0) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                Não há Evento Financeiro para esta Etapa.
              </Typography>
            )}

            {(produtoServicoRecordList.length !== 0) && (
              <React.Fragment>
                <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                  Abaixo estão listados os Eventos Financeiros para esta Etapa, bem como o Saldo calculado.
                </Typography>

                <a href="#" onClick={() => {this.onProdutoServicoConsultaAssociadoDetalhar(etapaRecord);}}>
                  <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                    Ficha Financeira da Etapa
                  </Typography>
                </a>

                <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "row nowrap", paddingTop: theme.spacing(2)})}>
                  <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                    {"Saldo:"}
                  </Typography>
                  <div style={{flex: "1 1 auto", minWidth: "30px"}}/>
                  <Typography /*className={classes.groupText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'normal', paddingTop: theme.spacing(0), textAlign: "right"})} variant="body2">
                    {"R$ " + formatter.format(saldo)}
                  </Typography>
                </Box>
                <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "row nowrap", paddingTop: theme.spacing(2)})}>
                  <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(2), color: theme.palette.primary.main})} variant="body2">
                    {"Total PIX a Confirmar:"}
                  </Typography>
                  <div style={{flex: "1 1 auto", minWidth: "30px"}}/>
                  <Typography /*className={classes.groupText}*/ variant="body2" sx={(theme) => ({paddingTop: theme.spacing(0), textAlign: "right"})}>
                    {"R$ " + formatter.format(totalPIX)}
                  </Typography>
                </Box>
              </React.Fragment>
            )}
            <br/>
          </Box>
        </div>
      </Box>
    );
  }

  /* prettier-ignore */
  renderResultados(etapaRecord) {
    const context = this.context;
    const app = context.app;
    const appState = app.state;
    const usuario = appState.usuario;
    const campeonato = (Validation.isDefined(usuario) ? usuario.campeonato : null);

    /*
    const {
      //theme,
      classes
    } = this.props;
    */

    const produtoServicoRecordList = Validation.isDefined(etapaRecord.produtoServicoRecordList) ? etapaRecord.produtoServicoRecordList : [];

    return (
      <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", width: "300px", padding: theme.spacing(1)})}>
        <div style={{display: "flex", flexFlow: "row nowrap"}}>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", width: theme.spacing(5)})}>
            <div style={{flex: "0 0 auto", display: "flex", justifyContent: "center"}}>
              <Avatar sx={(theme) => ({backgroundColor: theme.palette.primary.light})}>
                <ClassificacaoIcon/>
              </Avatar>
            </div>
            {(produtoServicoRecordList.length === -1) && (
              <div style={{flex: "1 1 auto", display: "flex", justifyContent: "center", paddingTop: "4px", paddingBottom: "4px"}}>
                <Divider orientation="vertical" style={{width: "2px"}}/>
              </div>
            )}
          </Box>
          <Box sx={(theme) => ({display: "flex", flexFlow: "column nowrap", paddingLeft: theme.spacing(2), overflow: "hidden"})}>
            <Box sx={(theme) => ({flex: "1 1 auto", display: "flex", flexFlow: "column nowrap", height: theme.spacing(5), justifyContent: "center"})}>
              <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0),})} variant="subtitle1">
                Resultados
              </Typography>
            </Box>
            {(etapaRecord.isInTheFuture === true) && (
              <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                Abaixo estarão disponíveis os relatórios de Classificação dos Atiradores da Etapa.
              </Typography>
            )}
            {(etapaRecord.isInTheFuture !== true) && (
              <React.Fragment>
                <Typography /*className={classes.groupDescriptionText}*/ sx={(theme) => ({color: theme.palette.text.secondary, fontWeight: 'normal',})} variant="body2">
                  Abaixo estão disponíveis os relatórios de Classificação dos Atiradores da Etapa.
                </Typography>

                {(campeonato === "cctp") && (
                  <a href="#" onClick={() => {this.onResultadoMelhorSerieEtapaListar(etapaRecord);}}>
                    <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(0), textAlign: "right"})} variant="body2">
                      Melhores Séries da Etapa
                    </Typography>
                  </a>
                )}
                <a href="#" onClick={() => {this.onResultadoTrapIndividualListar(etapaRecord);}}>
                  <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(0), textAlign: "right"})} variant="body2">
                    Resultado Trap Individual
                  </Typography>
                </a>

                {(etapaRecord.et_campeonato !== 9) && (
                  <a href="#" onClick={() => {this.onResultadoTrapEquipeListar(etapaRecord);}}>
                    <Typography /*className={classes.groupBodyHeaderText}*/ sx={(theme) => ({whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.text.secondary, fontWeight: 'bold', paddingLeft: theme.spacing(0), paddingTop: theme.spacing(0), textAlign: "right"})} variant="body2">
                      Resultado Trap por Equipe
                    </Typography>
                  </a>
                )}

              </React.Fragment>
            )}
          </Box>
        </div>
      </Box>
    );
  }
}

//export default withStyles(styles, {withTheme: true})(AppMenuAssociado);
export default AppMenuAssociado;
